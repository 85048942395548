<template>
  <div id="printMe" class="p-6 space-y-6">
    <div class="flex items-center justify-center col-span-4 space-x-2">
      <button @click="previousMonth">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <datepicker
        style="cursor: pointer !important; width: 9rem"
        class="
          border-none
          text-2xl text-center
          date-input
          bg-secondary
          text-white
          rounded-md
          py-0
        "
        :placeholder="'Month'"
        :inputFormat="calFormat"
        :minimumView="minimumView"
        :maximumView="'year'"
        :initialView="'year'"
        :locale="lan"
        v-model="staticDate"
      ></datepicker>
      <button @click="nextMonth">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
    <ul class="nav flex justify-center print-display">
      <li class="nav-item ml-1.5 mr-1.5" v-for="item in types" :key="item">
        <button
          @click="changeCalFormat(item)"
          :class="{
            'bg-primary border-2 border-secondary': filter_type == item,
          }"
          class="
            mybtn
            border-primary border-2
            md:text-base
            hover:text-white hover:bg-primary hover:border-secondary
          "
        >
          <span>{{ $t(item) }}</span>
        </button>
      </li>
    </ul>
    <div class="xs:grid grid-cols-2 sm:grid-cols-3 gap-4">
      <div class="input w-full sm:col-span-1 print-display">
        <div class="block relative my-2 w-full">
          <span>{{ $t("Person") }}</span>
          <multiselect
            v-model="person"
            :options="persons"
            label="name"
            track-by="id"
            :multiple="true"
            selectedLabel
            selectLabel
            deselectLabel
            :placeholder="$t('filter person')"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <div v-if="statisticList.subCategories">
      <div
        class="
          effektive-bewegungen
          border-b-2 border-gray-300
          pb-8
          overflow-x-auto
        "
        v-for="(cat, index) in Object.keys(statisticList.subCategories)"
        :key="index"
      >
        <div class="flex justify-between text-xl font-bold print-w-full">
          <h2>{{ $t(cat) }}</h2>
        </div>
        <section class="ef-bewegungen w-640 sm:w-full">
          <div class="ef-bewegungen-top-row">
            <div
              class="
                text-sm
                justify-between
                text-secondary
                font-semibold
                rounded
                border-2 border-secondary
                px-5
                py-1
                grid grid-cols-3
                gap-2
                print-flex print-flex-row
              "
            >
              <div class="ef-bew-cat col-span-1 print-w-4 print-text-lg">
                <span>{{ $t("Category") }}</span>
              </div>
              <!-- <div class="ef-bew-betrag col-span-1 text-center">
                <span>+/-</span>
              </div> -->
              <div
                class="
                  ef-bew-betrag
                  col-span-1
                  text-right
                  pr-14
                  print-w-4 print-text-right print-text-lg
                "
              >
                <span>{{ $t("amount in Euro") }}</span>
              </div>
              <div
                class="
                  ef-bew-pers
                  col-span-1
                  text-center
                  print-w-4 print-text-right print-text-lg
                "
              >
                <span>{{ $t("Person") }}</span>
              </div>
            </div>
          </div>
          <div
            v-for="(subCat, index) in Object.keys(
              statisticList.subCategories[cat]
            )"
            :key="index"
          >
            <div
              class="
                text-sm
                justify-between
                px-5
                py-1
                grid grid-cols-3
                gap-2
                font-semibold
                mt-1
                bg-primary
                print-flex print-flex-row
              "
            >
              <div class="ef-bew-cat col-span-1 print-w-4">
                <span> {{ $t(subCat) }}</span>
              </div>
              <!-- <div class="ef-bew-cat col-span-1 text-center">
                <span>
                  {{ statisticList.subCategories[cat][subCat].type }}</span
                >
              </div> -->
              <div
                class="
                  ef-bew-cat
                  col-span-1
                  text-right
                  pr-14
                  print-w-4 print-text-right
                "
              >
                <span>
                  {{
                    statisticList.subCategories[cat][subCat].type == "+"
                      ? ""
                      : cat == "saves"
                      ? ""
                      : "-"
                  }}{{
                    parseFloat(
                      statisticList.subCategories[cat][subCat].total
                    ).toLocaleString("de-DE", { minimumFractionDigits: 2 })
                  }}</span
                >
              </div>
            </div>
            <div
              v-for="(subItem, index) in statisticList.subCategories[cat][
                subCat
              ].items"
              :key="index"
              class="
                text-sm
                justify-between
                px-5
                py-1
                grid grid-cols-3
                gap-2
                font-semibold
                mt-1
                bg-gray-100
                print-flex print-flex-row
              "
            >
              <div class="ef-bew-cat col-span-1 print-w-4">
                <span> {{ $t(subItem.category_name) }}</span>
              </div>
              <!-- <div class="ef-bew-cat col-span-1 text-center">
                <span>
                  {{ statisticList.subCategories[cat][subCat].type }}</span
                >
              </div> -->

              <div
                :class="{
                  'text-green-500': cat == 'incomes',
                  'text-red-500': cat == 'expenses',
                  'text-gray-700': cat == 'saves',
                }"
                class="
                  ef-bew-cat
                  col-span-1
                  text-right
                  pr-14
                  print-w-4 print-text-right
                "
              >
                <span>
                  {{
                    statisticList.subCategories[cat][subCat].type == "+"
                      ? ""
                      : cat == "saves"
                      ? ""
                      : "-"
                  }}{{
                    parseFloat(subItem.total_amount).toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                    })
                  }}</span
                >
              </div>
              <div
                class="
                  ef-bew-cat
                  col-span-1
                  text-center
                  print-w-4 print-text-right
                "
              >
                <span class="capitalize">
                  {{ subItem.name ? subItem.name : $t("together") }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="
              text-sm
              justify-between
              py-1
              grid grid-cols-3
              gap-2
              font-semibold
              mt-1
              border-t-2 border-gray-900
              print-flex print-flex-row
            "
          >
            <div class="col-span-1 print-w-4">
              <span class="capitalize"> {{ $t("Sum of") }} {{ $t(cat) }} </span>
            </div>

            <div
              class="
                col-span-1
                text-right
                pr-16
                text-gray-700
                print-w-4 print-text-right
              "
            >
              <span
                :class="{
                  'text-green-500':
                    cat == 'incomes' &&
                    sum(statisticList.subCategories[cat]).toLocaleString(
                      'de-DE',
                      { minimumFractionDigits: 2 }
                    ) !== '0,00',
                  'text-red-500':
                    cat == 'expenses' &&
                    sum(statisticList.subCategories[cat]).toLocaleString(
                      'de-DE',
                      { minimumFractionDigits: 2 }
                    ) !== '0,00',
                }"
              >
                {{
                  cat == "incomes" || cat == "saves"
                    ? ""
                    : sum(statisticList.subCategories[cat]).toLocaleString(
                        "de-DE",
                        { minimumFractionDigits: 2 }
                      ) !== "0,00"
                    ? "-"
                    : ""
                }}
                {{
                  sum(statisticList.subCategories[cat]).toLocaleString(
                    "de-DE",
                    { minimumFractionDigits: 2 }
                  )
                }}
              </span>
            </div>
          </div>
        </section>
      </div>
      <div class="overflow-x-auto print-mt-3 print-border-top">
        <div class="w-640 sm:w-full">
          <div
            class="
              text-sm
              justify-between
              py-1
              grid grid-cols-3
              gap-2
              font-semibold
              mt-1
              bg-primary
              print-flex print-flex-row
            "
          >
            <div class="ef-bew-cat col-span-1 print-w-4">
              <span> {{ $t("Start stand") }}</span>
            </div>
            <!-- <div class="ef-bew-cat col-span-1 text-center">
              <span> +</span>
            </div> -->
            <div
              class="
                ef-bew-cat
                col-span-1
                text-right
                pr-14
                print-w-4 print-text-right
              "
            >
              <span>
                {{
                  (
                    +statisticList.totalAccountsCredit +
                    +statisticList.beforeTotalAmount
                  ).toLocaleString("de-DE", { minimumFractionDigits: 2 })
                }}</span
              >
            </div>
          </div>
          <div
            class="
              text-sm
              justify-between
              py-1
              grid grid-cols-3
              gap-2
              font-semibold
              mt-1
              bg-gray-100
              print-flex print-flex-row
            "
          >
            <div class="ef-bew-cat col-span-1 print-w-4">
              <span> {{ $t("From reserves") }}</span>
            </div>
            <!-- <div class="ef-bew-cat col-span-1 text-center">
              <span> -</span>
            </div> -->
            <div
              class="
                ef-bew-cat
                col-span-1
                text-right
                pr-14
                print-w-4 print-text-right
              "
            >
              <span>
                {{
                  parseFloat(statisticList.beforeTotalSaves).toLocaleString(
                    "de-DE",
                    { minimumFractionDigits: 2 }
                  )
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto">
        <div class="w-640 sm:w-full">
          <div
            class="
              text-sm
              justify-between
              py-1
              grid grid-cols-3
              gap-2
              font-semibold
              mt-1
              bg-primary
              print-flex print-flex-row
            "
          >
            <div class="ef-bew-cat col-span-1 print-w-4">
              <span> {{ $t("end result") }} </span>
            </div>
            <!-- <div class="ef-bew-cat col-span-1 text-center">
              <span> +</span>
            </div> -->
            <div
              class="
                ef-bew-cat
                col-span-1
                text-right
                pr-14
                print-w-4 print-text-right
              "
            >
              <span>
                {{
                  (
                    +statisticList.totalAccountsCredit +
                    +statisticList.total +
                    +statisticList.beforeTotalAmount
                  ).toLocaleString("de-DE", { minimumFractionDigits: 2 })
                }}</span
              >
            </div>
          </div>
          <div
            class="
              text-sm
              justify-between
              py-1
              grid grid-cols-3
              gap-2
              font-semibold
              mt-1
              bg-gray-100
              print-flex print-flex-row
            "
          >
            <div class="ef-bew-cat col-span-1 print-w-4">
              <span> {{ $t("From reserves") }}</span>
            </div>
            <!-- <div class="ef-bew-cat col-span-1 text-center">
              <span> -</span>
            </div> -->
            <div
              class="
                ef-bew-cat
                col-span-1
                text-right
                pr-14
                print-w-4 print-text-right
              "
            >
              <span>
                {{
                  parseFloat(statisticList.balancedTotalSaves).toLocaleString(
                    "de-DE",
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
      :loader="'dots'"
      :color="'#963d97'"
    />
  </div>
</template>

<script>
const monthList = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];
const types = ["monthly", "yearly"];

import Multiselect from "@suadelabs/vue3-multiselect";
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";
import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: { Multiselect, Datepicker, Loading },

  data() {
    return {
      monthList,
      person: null,
      filter_type: "monthly",
      types,
      touchBtn: false,
      persons: [{ id: "common", name: this.$t("common") }],
      staticDate: new Date(),
      lan: this.$i18n.locale == "de" ? de : it,
      calFormat: "MM.yyyy",
      minimumView: "month",
      isLoading: false,
    };
  },
  watch: {
    staticDate: function () {
      this.getStatisticList();
    },
    person() {
      this.getStatisticList();
    },
    filter_type() {
      this.getStatisticList();
    },
    year() {
      if (!this.touchBtn) {
        this.getStatisticList();
      }
    },
    month() {
      if (!this.touchBtn) {
        this.getStatisticList();
      }
    },
    getPersons() {
      this.getPersons.map((i) => {
        this.persons.push(i);
      });
    },
  },
  computed: {
    statisticList() {
      return this.$store.state.statistic.statisticList;
    },
    getPersons() {
      return this.$store.state.User.persons.data;
    },
  },
  methods: {
    getStatisticList() {
      this.isLoading = true;
      let person = null;
      if (this.person && this.person.length) {
        person =
          this.person && Object.values(this.person).length > 1
            ? Object.values(this.person.map((p) => p.id)).join(",")
            : this.person[0].id;
      }
      this.$store
        .dispatch("statistic/getStatisticList", {
          from: `${this.staticDate.getFullYear()}-${
            this.filter_type == "monthly"
              ? (this.staticDate.getMonth() + 1).toString().padStart(2, "0")
              : "1"
          }-1`,
          to: `${this.staticDate.getFullYear()}-${
            this.filter_type == "monthly"
              ? (this.staticDate.getMonth() + 1).toString().padStart(2, "0")
              : "12"
          }-${this.endMonthDay()}`,

          person_id: person,
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    sum(data) {
      return parseFloat(
        Object.keys(data).reduce((t, i) => t + data[i].total, 0)
      ).toLocaleString("de-DE", { minimumFractionDigits: 2 });
    },
    changeCalFormat(item) {
      this.filter_type = item;

      if (this.filter_type == "monthly") {
        this.calFormat = "MM.yyyy";
        this.minimumView = "month";
      } else {
        this.calFormat = "yyyy";
        this.minimumView = "year";
      }
    },
    endMonthDay() {
      const endDate = new Date(
        this.staticDate.getFullYear(),
        this.staticDate.getMonth() + 1,
        0
      );
      return endDate.getDate();
    },
    nextMonth() {
      if (this.filter_type == "monthly") {
        if (this.staticDate.getMonth() == 11) {
          this.staticDate = new Date(this.staticDate.getFullYear() + 1, 0, 1);
        } else {
          this.staticDate = new Date(
            this.staticDate.getFullYear(),
            this.staticDate.getMonth() + 1,
            1
          );
        }
      } else {
        this.staticDate = new Date(this.staticDate.getFullYear() + 1, 11, 31);
      }
    },
    previousMonth() {
      if (this.filter_type == "monthly") {
        if (this.staticDate.getMonth() == 0) {
          this.staticDate = new Date(this.staticDate.getFullYear() - 1, 11, 1);
        } else {
          this.staticDate = new Date(
            this.staticDate.getFullYear(),
            this.staticDate.getMonth() - 1,
            1
          );
        }
      } else {
        this.staticDate = new Date(this.staticDate.getFullYear() - 1, 11, 31);
      }
    },
  },
  mounted() {
    this.getStatisticList();
    this.$store.dispatch("User/getPersons");
  },
};
</script>

<style></style>
